import React,{ useState,useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {connect} from 'react-redux';
import swal from 'sweetalert';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MUIDataTable from "mui-datatables";
import {API_URL} from '../../config.json';
import {BrowserRouter as Router,Route,Link} from 'react-router-dom'
import axios from 'axios';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { Form, Radio } from "semantic-ui-react";
import { useHistory } from "react-router-dom";


import { Modal } from 'react-responsive-modal';
import "react-responsive-modal/styles.css";



import EditIcon from '@material-ui/icons/Edit';


import DeleteIcon from '@material-ui/icons/Delete';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';


import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';


import {currentRouteSet} from '../../actions/actions';

import {pathSpliter,checkAuthBranchWare,dateTimeFormat,currentDateTime, dateFormat} from '../../lib/functions'
import moment from 'moment';


import _ from 'lodash';
import commaNumber from 'comma-number';
let format = commaNumber.bindWith(',', '.')

const GroupManage = ({location,currentRouteSet,currentRoute,authInfo})=>{
    const classes = useStyles();
    const history = useHistory();

  
      // Initial values Set - Start
      let [mf_id,mf_id_set] = useState(0)
      let [pd_ind_id,pd_ind_id_set] = useState(null);
      let [raw_ind_id,raw_ind_id_set] = useState(null);
      let [action,action_set] = useState('create');
      let [mf_voucher_no,mf_voucher_no_set] = useState('');
      let [is_warehouse,is_warehouse_set] = useState('')
      let [currency,currency_set] = useState('')
      let [is_serial,is_serial_set] = useState('no');
  
      let [per_raw_conversion,per_raw_conversion_set] = useState(0)
      let [per_pd_conversion,per_pd_conversion_set] = useState(0)

      const [serialModal,serialModalSet] = useState(false)
      const [serial_number,serial_number_set] = useState('')
  
      let [serials,serialsSet] = useState([]);
      let [rawSerials,rawSerialsSet] = useState([]);
      let [items,itemsSet] = useState([]);
      let [rows,rowsSet] = useState([]);
      let [itemCart,itemCartSet] = useState([])
      let [rawCart,rawCartSet] = useState([])
      let [warehouses,warehouses_set] = useState([])
  
      
      let [loadingSave,loadingSaveSet] = useState(false)
  
      
  
      let [selectedItemWarehouse,selectedItemWarehouseSet] = useState(null);
      let [selectedRawWarehouse,selectedRawWarehouseSet] = useState(null);
      let [selectedItem,selectedItemSet] = useState(null);
      let [selectedRaw,selectedRawSet] = useState(null);

      let [rawItemInfo,rawItemInfoSet] = useState({
        current_qty : 0,
        display_qty : '',
        rate : 0,
      })
  
  
  
      const [created_date, created_date_set] = useState(currentDateTime);
  
      
  
      let [orderId,orderIdSet] = useState(0);


      let [item_qty,item_qty_set] = useState('');
      let [item_rate,item_rate_set] = useState('');
      let [item_total,item_total_set] = useState(0);
      let [retail_qty,retail_qty_set] = useState(0);
      let [pd_qty,pd_qty_set] = useState(0);
      let [pd_rate,pd_rate_set] = useState(0);
      let [conversion,conversion_set] = useState(0)
      let [item_percentage,item_percentage_set] = useState(0)
      


      let [labour_cost,labour_cost_set] = useState(0)
      let [others_cost,others_cost_set] = useState(0)
      let [total_cost,total_cost_set] = useState(0)
  
  
      let [raw_item_qty,raw_item_qty_set] = useState('');
      let [raw_item_rate,raw_item_rate_set] = useState('');
      let [raw_item_total,raw_item_total_set] = useState(0);
      let [raw_retail_qty,raw_retail_qty_set] = useState(0);
      let [raw_qty,raw_qty_set] = useState(0);
      let [raw_rate,raw_rate_set] = useState(0);
      let [raw_conversion,raw_conversion_set] = useState(0)
      let [availableSerials,availableSerialsSet] = useState([]);

  
      let [narration,narration_set] = useState('');
  

      const [item_name, item_name_set] = useState("");
      const [raw_name, raw_name_set] = useState("");
      const [raw_serial_number, raw_serial_number_set] = useState("");



      let [rawUnits,rawUnitsSet] = useState([]);
      let [pdUnits,pdUnitsSet] = useState([]);
      let [selectedRawUnit,selectedRawUnitSet] =  useState(null);
      let [selectedPdUnit,selectedPdUnitSet] =  useState(null);


// For Consume item

      useEffect(()=>{
        if(selectedRaw != null){
          rawUnitsSet(selectedRaw.units)

          if(raw_ind_id == null){
            selectedRawUnitSet(selectedRaw.units[0])

          }
        }else{
          rawUnitsSet([])
          selectedRawUnitSet(null)
        }
      },[selectedRaw])

      useEffect(()=>{
        if(selectedRaw != null && selectedRawUnit == null  ){
           selectedRawUnitSet(selectedRaw.units[0])

          
        }

        if(selectedRawUnit != null){
          per_raw_conversion_set(selectedRawUnit.conversion)
        }
      },[selectedRawUnit])
  


// For Production item
      useEffect(()=>{
        if(selectedItem != null){
          pdUnitsSet(selectedItem.units)

          if(pd_ind_id == null){
            selectedPdUnitSet(selectedItem.units[0])

          }
        }else{
        pdUnitsSet([])
          selectedPdUnitSet(null)
        }
      },[selectedItem])

      useEffect(()=>{
        if(selectedItem != null && selectedPdUnit == null  ){
          selectedPdUnitSet(selectedItem.units[0])

          
        }

        if(selectedPdUnit != null){
          per_pd_conversion_set(selectedPdUnit.conversion)
        }
      },[selectedPdUnit])
  

      

      // Init value End

 // API Requests - Start
  useEffect(()=>{
    mf_id_set(pathSpliter(location.pathname,3) != undefined?parseFloat( pathSpliter(location.pathname,3)):0);
    currentRouteSet(pathSpliter(location.pathname,1));

    if(pathSpliter(location.pathname,3) != undefined?parseFloat( pathSpliter(location.pathname,3)):0 != 0 ){
      action_set('update')
    }
   
    is_warehouse_set(authInfo.is_warehouse)
    currency_set(authInfo.currency)
    if(authInfo.is_warehouse == 'yes'){
      getWarehouses()
    }
 

    getManuInv()
    item_ref.current.focus()
    if(pathSpliter(location.pathname,3) != undefined?parseFloat( pathSpliter(location.pathname,3)):0 != 0){
      loadingSaveSet(true)
      getPurchaseRecordWithDetail();
    }
  },[]);

  useEffect(()=>{
    getItems()
  },[item_name])

  useEffect(()=>{
    getRowItems()
  },[raw_name])





  let getPurchaseRecordWithDetail = async ()=>{
      let url = `/api/get-manufacturing-record-with-details`
      let para = {mf_id:pathSpliter(location.pathname,3) != undefined?parseFloat( pathSpliter(location.pathname,3)):0}
      // if(pathSpliter(location.pathname,2) == 'order-to-purchase'){
      //   url = `/api/get-manufacturing-order-record-with-details`
      //   para = {pd_o_id:pathSpliter(location.pathname,3) != undefined?parseFloat( pathSpliter(location.pathname,3)):0}
      // }
    await axios.post(`${API_URL}${url}`,para,{headers:{'auth-token':authInfo.token}}).then(res=>{
      let data = res.data[0];
      let dataMIDetails = res.data[0].miDetails;
      let dataMCIDetails = res.data[0].mciDetails;
      // if(pathSpliter(location.pathname,2) != 'order-to-manufacturing'){
         mf_voucher_no_set(data.mf_voucher_no);
         orderIdSet(data.order_id)

      // }else{
      //   orderIdSet(pathSpliter(location.pathname,3))
      // }
      



         

      
       created_date_set(data.created_date);
       narration_set(data.narration);
   
       is_serial_set(data.is_serial)

       labour_cost_set(data.labour_cost)
       others_cost_set(data.others_cost)
    

       // Manufacturing Detail Data to Product Cart - Start
      let itemCartData =  dataMIDetails.map((item)=>{ 
        let serials = []
        
        serials = item.serials.split(',');
        if(item.is_serial == 'yes' ){
          serials = serials.map((slNo)=>{
            return {serial_number : slNo}
          })
        }else{
          serials = []
        }


          let updateItemn = {
            warehouse_id : item.warehouse_id,
            warehouse_name : item.warehouse_name,
            item_id : item.item_id,
            item_name : item.item_name,
            display_text : item.display_text,
            is_serial: item.is_serial,
            item_qty:item.item_qty,
            item_rate:item.item_rate,
            item_percentage:item.item_percentage,
           
            serials,
            item_total:item.item_total,
            retail_qty:item.retail_qty,
            pd_qty:item.pd_qty,
            pd_rate:item.pd_rate,
            qty_display: item.item_qty +' '+ item.unit_symbol + (item.conversion >1 ? ', '+ item.retail_qty +' ' +item.base_unit_name:''),
            unit_symbol: item.unit_symbol,
            base_unit_name: item.base_unit_name,
            unit_name: item.unit_name,
            conversion: item.conversion,


            units: item.units,
            per_symbol : item.unit_symbol,
            per_conversion : item.per_conversion,
            per_unit_id : item.per_unit_id,
            per_unit_symbol: item.per_unit_symbol 

          }


          return updateItemn;
        });


        itemCartSet(itemCartData);

        // Manufacturing Detail Data to Product Cart - End




          // Consume Detail Data to Product Cart - Start
      let  rawCartData =  dataMCIDetails.map((item)=>{ 
        let serials = []
        
        serials = item.serials.split(',');
        if(item.is_serial == 'yes' ){
          serials = serials.map((slNo)=>{
            return {serial_number : slNo}
          })
        }else{
          serials = []
        }

          let updateItem = {
            warehouse_id : item.warehouse_id,
            warehouse_name : item.warehouse_name,
            item_id : item.item_id,
            item_name : item.item_name,
            display_text : item.display_text,
            is_serial: item.is_serial,
            raw_item_qty:item.raw_item_qty,
            raw_item_rate:item.raw_item_rate,
           
            serials,
            raw_item_total:item.raw_item_total,
            raw_retail_qty:item.raw_retail_qty,
            raw_qty:item.raw_qty,
            raw_rate:item.raw_rate,
            qty_display: item.raw_item_qty +' '+ item.unit_symbol + (item.conversion >1 ? ', '+ item.raw_retail_qty +' ' +item.base_unit_name:''),
            unit_symbol: item.unit_symbol,
            base_unit_name: item.base_unit_name,
            unit_name: item.unit_name,
            raw_conversion: item.conversion,

            units: item.units,
            per_symbol : item.unit_symbol,
            per_conversion : item.per_conversion,
            per_unit_id : item.per_unit_id,
            per_unit_symbol: item.per_unit_symbol
          }
          return updateItem;
        });
        rawCartSet(rawCartData);

        // Consume Detail Data to Product Cart - End



        loadingSaveSet(false)
    });
  }

 


        let getManuInv = async ()=>{
          await  axios.get(`${API_URL}/api/get-manufacturing-voucher-no`,{headers:{'auth-token':authInfo.token}}).then(res=>{
            mf_voucher_no_set(res.data)
      
          })
        }

 




 

  const getWarehouses = async() => {
      await axios.post(`${API_URL}/api/get-warehouses`,{"select-type":"active"},{headers:{'auth-token':authInfo.token}}).then(res=>{
         warehouses_set(res.data.message)
      })
  };

  const getItems = async() => {
      await axios.post(`${API_URL}/api/get-items-by-search`,{query:item_name.trim(),search:true},{headers:{'auth-token':authInfo.token}}).then(res=>{
         itemsSet(res.data)
      })
  };

  const getRowItems = async() => {
    await axios.post(`${API_URL}/api/get-items-by-search`,{query:raw_name.trim(),search:true},{headers:{'auth-token':authInfo.token}}).then(res=>{
       rowsSet(res.data)
    })
};


   // API Requests - End

 


  

    // Initial values Set - Start


    // Input Refs - Start
    let warehouse_ref = useRef(null)
    let item_ref = useRef(null)
    let item_qty_ref = useRef(null)
    let item_rate_ref = useRef(null)
    let retail_qty_ref = useRef(null)


    let raw_warehouse_ref = useRef(null)
    let raw_ref = useRef(null)
    let raw_item_qty_ref = useRef(null)
    let raw_item_rate_ref = useRef(null)
    let raw_retail_qty_ref = useRef(null)
    let raw_item_ref = useRef(null)

    
   
    
    // Input Refs - End


    // Input Calculations - Start

    /// for Item 
    useEffect(()=>{
      let total_qty = item_qty * conversion;
      total_qty += parseFloat(retail_qty)

        let cartTotal =  itemCart.reduce((prev,curr)=>{
          return prev+parseFloat(curr.item_total)
         },0)

        //  let cost = total_cost - cartTotal

       // Parcentage

       let totalQty =  itemCart.reduce((prev,curr)=>{
        return prev+parseFloat(curr.item_qty)
      },0)

      //  let calPercentage = itemCart.length != 0 ? (100 / totalQty ) : (100 / 1 )
      // let rateOfCosting = ((total_cost *  calPercentage)/100)/total_qty;

      let rateOfCosting = total_cost / (totalQty > 0 ? totalQty : 1)
      // End

      //             let perQtyRate = per_pd_conversion > 1? rateOfCosting / conversion : (rateOfCosting * conversion) / conversion;

      let perQtyRate = per_pd_conversion > 1? rateOfCosting  : (rateOfCosting * conversion) / conversion;
      item_rate_set(parseFloat(rateOfCosting * conversion).toFixed(2))
      pd_rate_set(perQtyRate);
      pd_qty_set(total_qty);
     
  let  total = parseFloat((total_qty * perQtyRate) ).toFixed(2);

  item_total_set(parseFloat(total).toFixed(2));


},[selectedItem,selectedPdUnit,item_qty,item_rate,retail_qty,item_percentage])



  

  let callAllProductionItem = (tempCart)=>{
    let newArr   =  tempCart.map((item,ind)=>{


      let total_qty = item.item_qty * item.conversion;
      total_qty += parseFloat(item.retail_qty)

     let totalQty =  tempCart.reduce((prev,curr)=>{
        return prev+parseFloat(curr.item_qty)
      },0)
       // Parcentage
      //  let calPercentage = tempCart.length != 0 ? (100 / totalQty ) : (100 / 1 )

      //  let rateOfCosting = ((total_cost * calPercentage )/100)/total_qty;
      let rateOfCosting = total_cost / (totalQty > 0 ? totalQty : 1)
       // End

       let perQtyRate = per_pd_conversion > 1? rateOfCosting  : (rateOfCosting * item.conversion) / item.conversion;


        item.pd_rate = perQtyRate;

        item.item_rate = parseFloat(rateOfCosting * item.conversion).toFixed(2);
        item.item_total = parseFloat((total_qty * perQtyRate) * item.conversion).toFixed(2);


        return item;
      });

        itemCartSet(newArr);  
  }

    useEffect(()=>{
      if(itemCart.length != 0){
        callAllProductionItem(itemCart);

      }
      
    },[total_cost]);

    



    useEffect(()=>{
      
      // let percentageOfRate = ((100 * (item_qty * rateOfCosting))/total_cost)

      // console.log(rateOfCosting,percentageOfRate,per)


      // item_percentage_set(percentageOfRate)
    },[item_percentage])


  
    useEffect(()=>{
    
     let subTotal =  itemCart.reduce((prev,curr)=>{
        return prev+parseFloat(curr.item_total)
      },0);


        let checkIsSerial =   itemCart.findIndex((ele)=>{
          if(ele.is_serial=='yes'){ 
            return true 
          }else{
            return false
          }
          }); 
      
          if(checkIsSerial>-1){
            is_serial_set('yes')
          }else{
            is_serial_set('no')
          }

    },[itemCart]);


 /// End 


// For Rows 


useEffect(()=>{
  let raw_total_qty = raw_item_qty * raw_conversion;
  raw_total_qty += parseFloat(raw_retail_qty)

  let perQtyRate = per_raw_conversion > 1? raw_item_rate / raw_conversion : (raw_item_rate * raw_conversion) / raw_conversion;


      raw_rate_set(perQtyRate);
      raw_qty_set(raw_total_qty);

      console.log(raw_conversion)
 
let  total = parseFloat(raw_total_qty * perQtyRate).toFixed(2);

raw_item_total_set(parseFloat(total).toFixed(2))


},[selectedRaw,selectedRawUnit,raw_item_qty,raw_item_rate,raw_retail_qty]) 

useEffect(()=>{
  if(selectedRaw != null){
  axios.post(`${API_URL}/api/get-current-stock`,{itemId:selectedRaw.item_id,
    warehouseId : selectedRawWarehouse != null ? selectedRawWarehouse.warehouse_id : 0
  },{headers:{'auth-token':authInfo.token}}).then((res)=>{
   

    rawItemInfoSet({
      current_qty : res.data[0].current_qty,
      rate : res.data[0].average_rate,
      display_qty : res.data[0].display_qty
    })



    raw_item_rate_set(res.data[0].average_rate * res.data[0].conversion)
    
  });
  }
},[selectedRaw,selectedRawWarehouse])





useEffect(()=>{

let subTotal =  rawCart.reduce((prev,curr)=>{
return prev+parseFloat(curr.raw_item_total)
},0);


let checkIsSerial =   rawCart.findIndex((ele)=>{
if(ele.is_serial=='yes'){ 
  return true 
}else{
  return false
}
}); 

if(checkIsSerial>-1){
  is_serial_set('yes')
}else{
  is_serial_set('no')
}

},[rawCart]);



// end


    // Input Calculations - End

    // Methods  -  start
  

       // Data Save Method
      
    const savePurchaseOrder = async ()=>{
          if(itemCart.length == 0){
            swal({
              title:`Item cart is Empty !`,
              icon:'warning'
            })
            return false
          }else{
           
            let purchaseOrderData = {
               itemCart,
                  rawCart,
              
                masterData:{

                  mf_voucher_no,
                  is_serial,
                  created_date,
                  narration,

                  labour_cost,
                  others_cost,
                  total_cost,


                  mf_id,
                  orderId: orderId==''?0:orderId,
                 
                
                   
                }
            }

            

        let url = `/api/create-manufacturing`
        if(pathSpliter(location.pathname,3) != undefined ){
            url = `/api/update-manufacturing`
        }
           loadingSaveSet(true) 
         await axios.post(`${API_URL}${url}`,purchaseOrderData,{headers:{'auth-token':authInfo.token}}).then(res=>{
          loadingSaveSet(false)

          if(res.data.error){
            swal({
              title: `${res.data.message}`,
              icon:'warning'
            })
            return false;
          }
          if(!res.data.error){
            swal({title:`${res.data.message}. Do you want to view invoice?`,icon:'success',buttons:true}).then(confirm=>{
              if(confirm){
                history.push(`/manufacturing/quick-manufacturing-voucher/${res.data.mf_id}`)
               
              }else{
                window.location.reload()
              }
            })
          }

          

         })


          }

            
          
          
    }


      // Item cart methods - Start

  const addTOCart = ()=>{

    if(selectedItem.item_id == 0){
      swal({
        title:`Select Item`,
        icon:'warning'
      });
      item_ref.current.focus()
      return false
    }

    if(selectedItem.is_serial == 'yes' && action == 'update' && pd_ind_id == null){
      swal({
        title:`Serial Item can't add in Update. You can create new Invoice!`,
        icon:'warning'
      })
      return false
    }
    
    if(is_warehouse == 'yes' && selectedItemWarehouse == null){
      swal({
        title:`Select Warehouse`,
        icon:'warning'
      });
      warehouse_ref.current.focus()
      return false
    }
    //  if(item_qty < 1){
    //   swal({
    //     title:`Invalid Quantity !`,
    //     icon:'warning'
    //   });
    //   item_qty_ref.current.focus()
    //   return false
    // }




    if( selectedItem.is_serial == 'yes' && serials.length == 0  ){
      swal({title:'Serial  cart is Empty.',icon:'warning'});return false;
    }

    if( selectedItem.is_serial == 'yes' && serials.length !=  pd_qty  ){
      swal({title:'Serial  & Item quantity   should be same',icon:'warning'});return false;
    }


    let obj = {
      warehouse_id : selectedItemWarehouse != null ? selectedItemWarehouse.warehouse_id :0,
      warehouse_name : selectedItemWarehouse != null ? selectedItemWarehouse.warehouse_name :'',
      item_id : selectedItem.item_id,
      item_name : selectedItem.item_name,
      display_text : selectedItem.display_text,
      is_serial: selectedItem.is_serial,
      item_qty,
      item_rate,
      serials,
      item_total,
      item_percentage,
      retail_qty,
      pd_qty,
      pd_rate,
      qty_display: item_qty +' '+ selectedItem.unit_symbol + (conversion >1 ? ', '+ retail_qty +' ' +selectedItem.base_unit_name:''),
      unit_symbol: selectedItem.unit_symbol,
      base_unit_name: selectedItem.base_unit_name,
      unit_name: selectedItem.unit_name,
      conversion: conversion,


      units:pdUnits,
      per_unit_symbol : selectedPdUnit.unit_symbol,
      per_conversion : selectedPdUnit.conversion,
      per_unit_id : selectedPdUnit.unit_id,
   }

   

    let checkExists =   itemCart.findIndex((ele)=>{
        let warehouseId = selectedItemWarehouse == null ? 0 : selectedItemWarehouse.warehouse_id;
        if(ele.item_id==selectedItem.item_id && ele.warehouse_id == warehouseId){ 
          return true 
        }else{
          return false
        }
    }); 

    let tempCart = []

    if(checkExists>-1 && pd_ind_id != null){
      let preCopy =  [...itemCart];
      preCopy[pd_ind_id] = obj
      itemCartSet(preCopy)
      tempCart = preCopy
    }else{
      itemCartSet([...itemCart,obj])
      tempCart = [...itemCart,obj]

    }


     selectedItemWarehouseSet(null)
     selectedItemSet(null)
     item_qty_set('')
     item_rate_set('')

     pd_qty_set(0)
     pd_rate_set(0)
     retail_qty_set(0)
     item_percentage_set(0)


     pd_ind_id_set(null)

     serialsSet([])
     item_name_set('')



      item_ref.current.focus()

      callAllProductionItem(tempCart)

    }


    useEffect(()=>{
     let rawTotalAmount =  _.sumBy(rawCart,(item)=>Number(item.raw_item_total))
         total_cost_set(parseFloat(rawTotalAmount)+parseFloat(labour_cost)+parseFloat(others_cost))
    },[rawCart,labour_cost,others_cost])

    

   

    useEffect(()=>{
      if(selectedRaw != null && selectedRaw.is_serial == 'yes'){
         axios.post(`${API_URL}/api/get-available-serials`,{itemId:selectedRaw.item_id,warehouseId : selectedRawWarehouse != null ? selectedRawWarehouse.warehouse_id : 0},{headers:{'auth-token':authInfo.token}}).then((res)=>{
          availableSerialsSet(res.data)
        });
      }
    },[selectedRaw])



    // Row add to cart

    const addRawTOCart = ()=>{

      if(selectedRaw.item_id == 0){
        swal({
          title:`Select Raw Item`,
          icon:'warning'
        });
        raw_item_ref.current.focus()
        return false
      }

      if(selectedRaw.is_serial == 'yes' && action == 'update' && raw_ind_id == null){
        swal({
          title:`Serial Item can't add in Update. You can create new Invoice!`,
          icon:'warning'
        })
        return false
      }

      if(rawItemInfo.current_qty <= 0){
        swal({
          title:`Unavailable Raw Item`,
          icon:'warning'
        });
        return false
      }
      
      if(is_warehouse == 'yes' && selectedRawWarehouse == null){
        swal({
          title:`Select  Warehouse`,
          icon:'warning'
        });
        raw_warehouse_ref.current.focus()
        return false
      }
   

      if( rawItemInfo != null && rawItemInfo.current_qty < raw_qty ){
        swal({
          title:`Stock is Unavailable !`,
          icon:'warning'
        });
        return false;
      }
  
  
  
  
  
      if( selectedRaw.is_serial == 'yes' && rawSerials.length == 0  ){ 
        swal({title:'Row Serial  cart is Empty.',icon:'warning'});return false;
      }
  
      if( selectedRaw.is_serial == 'yes' && rawSerials.length !=  raw_item_qty  ){
        swal({title:'Row Serial  & Row Item quantity   should be same',icon:'warning'});return false;
      }
  
  
      let obj = {
        warehouse_id : selectedRawWarehouse != null ? selectedRawWarehouse.warehouse_id :0,
        warehouse_name : selectedRawWarehouse != null ? selectedRawWarehouse.warehouse_name :'',
        item_id : selectedRaw.item_id,
        item_name : selectedRaw.item_name,
        display_text : selectedRaw.display_text,
        is_serial: selectedRaw.is_serial,
        raw_item_qty,
        raw_item_rate,
        serials:rawSerials,
        raw_item_total,
  
        raw_retail_qty,
        raw_qty,
        raw_rate,
        qty_display: raw_item_qty +' '+ selectedRaw.unit_symbol + (raw_conversion >1 ? ', '+ raw_retail_qty +' ' +selectedRaw.base_unit_name:''),
        unit_symbol: selectedRaw.unit_symbol,
        base_unit_name: selectedRaw.base_unit_name,
        unit_name: selectedRaw.unit_name,
        raw_conversion: raw_conversion,

        units:rawUnits,
        per_unit_symbol : selectedRawUnit.unit_symbol,
        per_conversion : selectedRawUnit.conversion,
        per_unit_id : selectedRawUnit.unit_id,
     }
  
     
  
      let checkExists =   rawCart.findIndex((ele)=>{
          let warehouseId = selectedRawWarehouse == null ? 0 : selectedRawWarehouse.warehouse_id;
          if(ele.item_id==selectedRaw.item_id && ele.warehouse_id == warehouseId){ 
            return true 
          }else{
            return false
          }
      }); 
  
      if(checkExists>-1 && raw_ind_id != null){
        let preCopy =  [...rawCart];
        preCopy[raw_ind_id] = obj
        rawCartSet(preCopy)
      }else{
        rawCartSet([...rawCart,obj])
      }
  
  
       selectedRawWarehouseSet(null)
       selectedRawSet(null)
       raw_item_qty_set(0)
       raw_item_rate_set('')
  
       raw_qty_set(0)
       raw_rate_set(0)
       raw_retail_qty_set(0)
  
  
       raw_ind_id_set(null)
       
       rawSerialsSet([])
       raw_name_set('')
  
  
        raw_ref.current.focus()
  
      }
  


    // end 
 

    const editRawCart = (raw,sl)=>{
  
       raw_ind_id_set(sl)
       selectedRawWarehouseSet({warehouse_id:raw.warehouse_id,warehouse_name:raw.warehouse_name});
       selectedRawSet({item_id:raw.item_id,item_name:raw.item_name,display_text:raw.display_text,is_serial:raw.is_serial,
        unit_symbol:raw.unit_symbol,base_unit_name:raw.base_unit_name,conversion:raw.raw_conversion,base_unit_name:raw.base_unit_name,unit_name:raw.unit_name,
        units: raw.units});
 

console.log(raw)
        selectedRawUnitSet({unit_symbol : raw.per_unit_symbol,conversion : raw.per_conversion,unit_id : raw.per_unit_id})
per_raw_conversion_set(raw.per_conversion)

       rawSerialsSet(raw.serials)
       raw_item_qty_set(raw.raw_item_qty)
       raw_item_rate_set(raw.raw_item_rate)
       raw_retail_qty_set(raw.raw_retail_qty)
       raw_qty_set(raw.raw_qty)
       raw_rate_set(raw.raw_rate)
       raw_conversion_set(raw.raw_conversion)

    }


    const rawCartRemove = (sl,item)=>{
      if(item.is_serial == 'yes' && action == 'update'){
        swal({
          title:`Serial Item can't Remove. You can Delete Invoice!`,
          icon:'warning'
        })
        return false
      }
      let preCopy =  [...rawCart];
      preCopy.splice(sl, 1);
      rawCartSet(preCopy)

    }
  
      // Item cart methods - End

      // Item serial - methods
      const rowSerialAdd = async ()=>{
        if(raw_serial_number.trim() == ''){
          swal({
            title:`Row Serial Number is Required.`,
            icon:'warning'
          })
        }else{
         let serialCartCheck =   rawSerials.findIndex((item)=>{
            return item.serial_number == serial_number.trim()
          });
  
          
  
  
          if(serialCartCheck > -1){
            swal({
              title:`Row Serial Number is exists on this serial cart `,
              icon:'warning'
            })
            return false
          }
          
          let itemCartCheck =   rawCart.findIndex((item)=>item.serials.some(s=> s.serial_number == serial_number.trim()));
  
          if(itemCartCheck > -1){
            swal({
              title:`Row Serial Number is exists on Item cart `,
              icon:'warning'
            })
          return false
          }else{
  
            let checkPreviousStock = false;
             await axios.post(`${API_URL}/api/check-serial-number`,{serial_number:serial_number.trim()},{headers:{'auth-token':authInfo.token}}).then((res)=>{
                 if(res.data.error){
                  checkPreviousStock = true
                 }
            });
  
            if(checkPreviousStock){
              swal({
                title:`Serial Number  exists on previous purchase or order`,
                icon:'warning'
              });
              return false;
            }else{
              rawSerialsSet([...serials,{serial_number:serial_number.trim()}])
              raw_serial_number_set('');
            }
            
  
          }
          
        }
    }


 

    const rowSerialRemove = (ind)=>{
      let hardCopy =  [...rawSerials];
      hardCopy.splice(ind, 1);
      rawSerialsSet(hardCopy)
    }






    // for item 
    const editItemCart = (raw,sl)=>{
  
      pd_ind_id_set(sl)
      selectedItemWarehouseSet({warehouse_id:raw.warehouse_id,warehouse_name:raw.warehouse_name});
      selectedItemSet({item_id:raw.item_id,item_name:raw.item_name,display_text:raw.display_text,is_serial:raw.is_serial,
       unit_symbol:raw.unit_symbol,base_unit_name:raw.base_unit_name,conversion:raw.conversion,base_unit_name:raw.base_unit_name,unit_name:raw.unit_name,
       units: raw.units});

     
      selectedPdUnitSet({unit_symbol : raw.per_unit_symbol,conversion : raw.per_conversion,unit_id : raw.per_unit_id})
      per_pd_conversion_set(raw.per_conversion)

      serialsSet(raw.serials)
      item_qty_set(raw.item_qty)
      item_rate_set(raw.item_rate)

      item_percentage_set(raw.item_percentage)
     
      retail_qty_set(raw.retail_qty)
      pd_qty_set(raw.pd_qty)
      pd_rate_set(raw.pd_rate)
      conversion_set(raw.conversion)

   }


   const itemCartItemRemove = (sl,item)=>{
    if(item.is_serial == 'yes' && action == 'update'){
      swal({
        title:`Serial Item can't Remove. You can Delete Invoice!`,
        icon:'warning'
      })
      return false
    }
     let preCopy =  [...itemCart];
     preCopy.splice(sl, 1);
     itemCartSet(preCopy)
    callAllProductionItem(preCopy);

   }
 
     // Item cart methods - End

     // Item serial - methods
     const serialAdd = async ()=>{
       if(serial_number.trim() == ''){
         swal({
           title:`Serial Number is Required.`,
           icon:'warning'
         })
       }else{
        let serialCartCheck =   serials.findIndex((item)=>{
           return item.serial_number == serial_number.trim()
         });
 
         
 
 
         if(serialCartCheck > -1){
           swal({
             title:`Serial Number is exists on this serial cart `,
             icon:'warning'
           })
           return false
         }
         
         let itemCartCheck =   itemCart.findIndex((item)=>item.serials.some(s=> s.serial_number == serial_number.trim()));
 
         if(itemCartCheck > -1){
           swal({
             title:`Serial Number is exists on Item cart `,
             icon:'warning'
           })
         return false
         }else{
 
           let checkPreviousStock = false;
            await axios.post(`${API_URL}/api/check-serial-number`,{serial_number:serial_number.trim()},{headers:{'auth-token':authInfo.token}}).then((res)=>{
                if(res.data.error){
                 checkPreviousStock = true
                }
           });
 
           if(checkPreviousStock){
             swal({
               title:`Serial Number  exists on previous purchase or order`,
               icon:'warning'
             });
             return false;
           }else{
             serialsSet([...serials,{serial_number:serial_number.trim()}])
             serial_number_set('');
           }
           
 
         }
         
       }
   }


   const serialRemove = (ind)=>{
     let hardCopy =  [...serials];
     hardCopy.splice(ind, 1);
     serialsSet(hardCopy)
   }
      // Item serial methods - end
    
    // Methods  -  End

    // Add Pay To cart
  

  

 

     const options = {
       filterType: 'checkbox',
       selectableRows: 'none',
       display: "excluded"
      }


    return (
    <div className={classes.root}>

     <Paper className={classes.paper} style={{marginTop:'-15px'}}>
    <h2 className={classes.pageEntryLabel}>Manufacturing Journal   {pathSpliter(location.pathname,3) == undefined ? 'Entry' :"Update"}  </h2>
      

        
   
      
      
      <Grid container spacing={2}>
      <Grid  xs={12} sm={4} style={{marginTop: '6px'}}>
      <p style={{fontSize:'14px',margin:'0px',paddin:'0px',textAlign:'left',fontWeight:'bold',padding:'1px',color:'#222',marginBottom:'8px',marginBottom:'8px'}}>Consum Raw Items Information</p>


      {
        is_warehouse == 'yes'?(<>

<Grid  item xs={12} sm={12} style={{marginBottom:'10px'}}>
    
      <Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={warehouses} 
              disabled={action == 'update' ?true:false}
              size="small"
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => option.warehouse_name}
              value={selectedRawWarehouse}
              onChange={(event,selectedObj)=>{
                selectedRawWarehouseSet(selectedObj)
              }}
              renderInput={(params) => (
                <TextField
                  inputRef={warehouse_ref}
                  onKeyDown={(e)=>{
                    if(e.key==='Enter'){
                      item_ref.current.focus()
                    }
                  }}
                  {...params}
                  label="Select Warehouse"
                  variant="outlined"
                
                />
              )}
          />
      </Grid>
        
        </>):''
      }
      
      {/* <p style={{fontSize:'10px',margin:'0px',paddin:'0px',textAlign:'left',fontWeight:'bold'}}>Select a Item (By Search)</p> */}
      <Grid  item xs={12} sm={12} style={{marginBottom:'10px'}}>
      
    {
     selectedRaw != null ? <>
       {
          rawItemInfo.current_qty > 0 ?(<p className="stock-availity">Available  : {rawItemInfo.display_qty}</p>)
          :(<p className="stock-unavaility">Unavailable  : {rawItemInfo.display_qty}</p>) 
       }
     </>:''
    }

      <Autocomplete 
          autoHighlight={true}
          openOnFocus={true}
          style={{ width: '100%' }}
          options={rows} 
          size="small" 
          classes={{
            option: classes.option,
          }}
          getOptionLabel={(option) => option.display_text}
          value={selectedRaw}
          onChange={(event,selectedObj)=>{
            selectedRawSet(selectedObj)
            if(selectedObj != null){
              raw_conversion_set(selectedObj.conversion)
            }
            
            raw_qty_set('')
            raw_retail_qty_set(0)
            raw_rate_set('')
          }}
          renderInput={(params) => (
            <TextField
              inputRef={raw_ref}
              onKeyDown={(e)=>{
                if(e.key==='Enter'){
                  raw_item_qty_ref.current.focus()
                }
              }}
              onChange={(e)=>raw_name_set(e.target.value)}
              {...params}
              label="Search Raw Item"
              variant="outlined"
            
            />
          )}
          
      />
     
      </Grid>





      {
          selectedRaw != null && selectedRaw.conversion > 1 ?(<>
          
          <Grid  item xs={12} sm={12} style={{marginBottom:'10px'}}>
    
      <Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={rawUnits} 
              size="small"
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => option.unit_symbol}
              value={selectedRawUnit}
              onChange={(event,selectedObj)=>{
                selectedRawUnitSet(selectedObj)
                if(selectedObj != null){
                  per_raw_conversion_set(selectedObj.conversion)

                }
              }}
              renderInput={(params) => (
                <TextField
                
                  {...params}
                  label="Per "
                  variant="outlined"
                
                />
              )}
          />
      </Grid>
          </>):''
        }
      {
selectedRaw != null && selectedRaw.is_serial == 'yes'?(<>

<Grid  item xs={12} sm={12} style={{marginBottom:'10px'}}>
      


      <Autocomplete 
          autoHighlight={true}
          openOnFocus={true}
          multiple
          limitTags={10}
          id="multiple-limit-tags" 
          disabled={action == 'update' ?true:false}
          style={{ width: '100%' }}
          options={availableSerials} 
          size="small"
          classes={{
            option: classes.option,
          }}
          getOptionLabel={(option) => option.serial_number}
          value={rawSerials}
          onChange={(event,selectedObj)=>{
            rawSerialsSet(selectedObj)
         
          
          }}
          renderInput={(params) => (
            <TextField
            
              {...params}
              label="Select Raw Serials"
              variant="outlined"
            
            />
          )}
          
      />
      </Grid>

</>):''
}


      <p style={{fontSize:'10px',margin:'0px',paddin:'0px',textAlign:'left',fontWeight:'bold',marginBottom:'5px'}}>{
           selectedRaw != null &&  raw_conversion > 1  ?  selectedRaw.unit_name :''
          }</p>

      <Grid container xs={12} sm={12} >
      
          
      <Grid item xs={12} sm={5}  style={{marginBottom:'10px'}}>
            <TextField type="number"  
            onBlur={(e)=>e.target.value=='' || e.target.value < 0?raw_item_qty_set(0):raw_item_qty_set(e.target.value)}
            autoComplete='off' className={classes.fullWidth} 
          label={`QTY ${selectedRaw != null &&  selectedRaw.item_id!=0?'('+selectedRaw.unit_symbol+')':''}`} name="raw_item_qty" value={raw_item_qty} 
          inputRef={raw_item_qty_ref}
          onKeyDown={(e)=>{
            if(e.key==='Enter' ){
              if( raw_conversion > 1){
                raw_retail_qty_ref.current.focus()
              }else{
                addRawTOCart()
              }
             
            }
          }}
          variant="outlined" size="small" onChange={(e)=> raw_item_qty_set(e.target.value)} />
                
                         
            </Grid>

            <Grid item xs={1} sm={1} ></Grid>
{
  raw_conversion > 1 ?(<>
            <Grid item xs={12} sm={6}  style={{marginBottom:'10px'}}>
            <TextField type="number"  
           
            autoComplete='off' className={classes.fullWidth} 
          label={` ${selectedRaw != null ? selectedRaw.base_unit_name:''}  `} name="raw_retail_qty" value={raw_retail_qty} 
          inputRef={raw_retail_qty_ref}
          onBlur={(e)=>e.target.value=='' || e.target.value < 0?raw_retail_qty_set(0):raw_retail_qty_set(e.target.value)}
          onKeyDown={(e)=>{
            if(e.key==='Enter'){
              addRawTOCart()
            }
          }}
          variant="outlined" size="small" onChange={(e)=> raw_retail_qty_set(e.target.value)} />
                
                         
            </Grid>
  </>):''

}
        


            <Grid item xs={12} sm={6}  style={{marginBottom:'10px'}}>
            <TextField type="number"  
            disabled
            inputRef={raw_item_rate_ref}
            onBlur={(e)=>e.target.value=='' || e.target.value < 0?raw_item_rate_set(0):raw_item_rate_set(e.target.value)}
            onKeyDown={(e)=>{
              if(e.key==='Enter'){
                addRawTOCart()
              
              }
            }}
           autoComplete='off' className={classes.fullWidth} 
         label="Rate" name="raw_item_rate" value={raw_item_rate} 
         variant="outlined" size="small" onChange={(e)=>raw_item_rate_set(e.target.value)} />
               
                         
            </Grid>

        
       </Grid>
   
   
        
         
         

    

            <Grid item xs={12} sm={12} >
            <TextField type="number"  
           disabled
           autoComplete='off' className={classes.fullWidth} 
         label="Total" name="raw_item_total" value={raw_item_total} 
         variant="outlined" size="small" onChange={(e)=>raw_item_total_set(e.target.value)} />
               
                         
            </Grid>

            

        <span>Press Enter Key to purchase Cart </span>


        <Grid item xs={12} sm={6} style={{marginTop: '20px'}}>
           <Button onClick={()=>addRawTOCart()} style={{background: 'rgb(109 113 113)',color: 'white',fontSize: '10px'}}>Add To Cart</Button>
        </Grid>





        <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
              <TableRow>
              <TableCell align="left"   style={{width:'1%'}}>SL</TableCell>
              <TableCell align="left"   style={{width:'15%'}}>Item</TableCell>
              {
                is_serial=='yes'?(<>
              <TableCell align="left"   style={{width:'14%'}}>Serials</TableCell>

                </>):''
              }
              
              {
                is_warehouse == 'yes'?(<>
                  <TableCell align="left"   style={{width:'10%'}}>Warehouse</TableCell>
                </>):''
              }
              <TableCell align="right"  style={{width:'10%'}}>QTY</TableCell>
              <TableCell align="right"  style={{width:'10%'}}>Rate (Per)</TableCell>
            
             
              <TableCell align="right"  style={{width:'10%'}}>Total</TableCell>
              <TableCell align="right"  style={{width:'10%'}}>Actions</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
              {
                rawCart.map((raw,sl)=>(<>
                   <TableRow key={sl} style={{background: sl == pd_ind_id ? '#dedede':'',  transition: '1s'}}>
                  <TableCell align="left">{sl+parseFloat(1)}</TableCell>
                  <TableCell align="left">{raw.item_name}</TableCell>

                  {
                    is_serial == 'yes'?(<>
                     <TableCell align="left">
                      {
                        raw.is_serial == 'yes'?(<>
                            {
                  
                            <>
                            ({raw.serials.map((serial)=>(
                          <> <span>{serial.serial_number}</span>, </> 
                          ))})
                          </>
                            
                              }
                          
                        </>):''
                      }
                  </TableCell>
                    
                    </>):''
                  }
                 {
                   is_warehouse =='yes'?(<>
                          <TableCell align="left">{raw.warehouse_name}</TableCell>
                   </>):''
                 }
                  <TableCell align="right">{raw.qty_display}</TableCell>
                  <TableCell align="right">{format(parseFloat(raw.raw_item_rate).toFixed(2))} ({raw.per_unit_symbol})</TableCell>
                 
                
                  <TableCell align="right">{format(parseFloat(raw.raw_item_total).toFixed(2))}</TableCell>
                  <TableCell align="right">
                  <EditIcon style={{cursor:'pointer',color:'#2e482e',marginLeft:'2px'}} onClick={()=>{editRawCart(raw,sl)}}></ EditIcon>
                  <span style={{width:'2px'}}> </span>
                  
                  <RemoveCircleIcon style={{cursor:'pointer',color:'#582727',marginLeft:'2px'}} onClick={()=>{rawCartRemove(sl,raw)}}></ RemoveCircleIcon>
                  </TableCell>
              
                  </TableRow>
                </>))
              }

              {
                itemCart.length > 0 ? (<>
                <TableRow style={{background: 'rgb(249 243 243)',transition: '1s'}}>
                    <TableCell align="right" colSpan={is_serial == 'yes'? (is_warehouse =='yes' ? 5 : 5-1) :(is_warehouse =='yes' ? 4 : 4-1)} style={{fontWeight:'bold'}}>Total  : </TableCell>
                    <TableCell align="right" style={{fontWeight:'bold'}}></TableCell>
                    <TableCell align="right" style={{fontWeight:'bold'}}> {format(parseFloat(_.sumBy(rawCart,(item)=>parseFloat(item.raw_item_total))).toFixed(2))}</TableCell>
                 </TableRow>
                </>):''
                 
              }
             
         


     
        </TableBody>
      </Table>
    </TableContainer>






      </Grid>
      <Grid item xs={12} sm={1}></Grid>

      <Grid item xs={12} sm={4}>
   


    
<p style={{fontSize:'14px',margin:'0px',paddin:'0px',textAlign:'left',fontWeight:'bold',padding:'1px',color:'#222',marginBottom:'8px'}}>Item cart / Product cart Information</p>


{
  is_warehouse == 'yes'?(<>

<Grid  item xs={12} sm={12} style={{marginBottom:'10px'}}>

<Autocomplete
        autoHighlight={true}
        openOnFocus={true}
        style={{ width: '100%' }}
        options={warehouses} 
        disabled={action == 'update' ?true:false}

        size="small"
        classes={{
          option: classes.option,
        }}
        getOptionLabel={(option) => option.warehouse_name}
        value={selectedItemWarehouse}
        onChange={(event,selectedObj)=>{
          selectedItemWarehouseSet(selectedObj)
        }}
        renderInput={(params) => (
          <TextField
            inputRef={warehouse_ref}
            onKeyDown={(e)=>{
              if(e.key==='Enter'){
                item_ref.current.focus()
              }
            }}
            {...params}
            label="Select Warehouse"
            variant="outlined"
          
          />
        )}
    />
</Grid>
  
  </>):''
}

{/* <p style={{fontSize:'10px',margin:'0px',paddin:'0px',textAlign:'left',fontWeight:'bold'}}>Select a Item (By Search)</p> */}
<Grid  item xs={12} sm={12} style={{marginBottom:'10px'}}>



<Autocomplete 
    autoHighlight={true}
    openOnFocus={true}
    style={{ width: '100%' }}
    options={items} 
    size="small"
    classes={{
      option: classes.option,
    }}
    getOptionLabel={(option) => option.display_text}
    value={selectedItem}
    onChange={(event,selectedObj)=>{
      selectedItemSet(selectedObj)
      if(selectedObj != null){
        if(itemCart.length == 0){
          item_percentage_set(100)
        }else{
          item_percentage_set(0)
        }
        conversion_set(selectedObj.conversion)
      }
      
      item_qty_set('')
      retail_qty_set(0)
      item_rate_set(0)
    }}
    renderInput={(params) => (
      <TextField
        inputRef={item_ref}
        onKeyDown={(e)=>{
          if(e.key==='Enter'){
            item_qty_ref.current.focus()
          }
        }}
        onChange={(e)=>item_name_set(e.target.value)}
        {...params}
        label="Search Production Item"
        variant="outlined"
      
      />
    )}
    
/>
</Grid>

{
          selectedItem != null && selectedItem.conversion > 1 ?(<>
          
          <Grid  item xs={12} sm={12} style={{marginBottom:'10px'}}>
    
      <Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={pdUnits} 
              size="small"
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => option.unit_symbol}
              value={selectedPdUnit}
              onChange={(event,selectedObj)=>{
                selectedPdUnitSet(selectedObj)
                if(selectedObj != null){
                  per_pd_conversion_set(selectedObj.conversion)

                }
              }}
              renderInput={(params) => (
                <TextField
                
                  {...params}
                  label="Per "
                  variant="outlined"
                
                />
              )}
          />
      </Grid>
          </>):''
        }

<Grid container xs={12} sm={12} >

<Grid item sm={1} style={{float:'left'}}>

  {
  selectedItem != null && selectedItem.is_serial == 'yes' && pd_ind_id == null?(<>
  <Button style={{    background: 'green',
  fontSize: '30px',
  display: 'contents',
  color:' #499a0c'}}  onClick={()=>serialModalSet(true)}>+</Button> 


  </>):''
  }

  </Grid>

  {
  selectedItem != null && selectedItem.is_serial == 'yes' && pd_ind_id == null?(<>
  <Grid item xs={12} sm={1}></Grid>
  <Grid item xs={12} sm={10} style={{float:'left'}}>
  <p>Click to Plus Button for  Add  Item's Serial </p>


  </Grid>

  </>):''
  }

</Grid>


<p style={{fontSize:'10px',margin:'0px',paddin:'0px',textAlign:'left',fontWeight:'bold',marginBottom:'5px'}}>{
     selectedItem != null &&  conversion > 1  ?  selectedItem.unit_name :''
    }</p>

<Grid container xs={12} sm={12} >

    
<Grid item xs={12} sm={5}  style={{marginBottom:'10px'}}>
      <TextField type="number"  
      onBlur={(e)=>e.target.value=='' || e.target.value < 0?item_qty_set(0):item_qty_set(e.target.value)}
      autoComplete='off' className={classes.fullWidth} 
    label={`QTY ${selectedItem != null &&  selectedItem.item_id!=0?'('+selectedItem.unit_symbol+')':''}`} name="item_qty" value={item_qty} 
    inputRef={item_qty_ref}
    onKeyDown={(e)=>{
      if(e.key==='Enter'){
        if( conversion > 1){
          retail_qty_ref.current.focus()
        }else{
          item_rate_ref.current.focus()
        }
       
      }
    }}
    variant="outlined" size="small" onChange={(e)=> item_qty_set(e.target.value)} />
          
                   
      </Grid>

      <Grid item xs={1} sm={1} ></Grid>
{
conversion > 1 ?(<>
      <Grid item xs={12} sm={6}  style={{marginBottom:'10px'}}>
      <TextField type="number"  
     
      autoComplete='off' className={classes.fullWidth} 
    label={` ${selectedItem != null ? selectedItem.base_unit_name:''}  `} name="retail_qty" value={retail_qty} 
    inputRef={retail_qty_ref}
    onBlur={(e)=>e.target.value=='' || e.target.value < 0?retail_qty_set(0):retail_qty_set(e.target.value)}
    onKeyDown={(e)=>{
      if(e.key==='Enter'){
        item_rate_ref.current.focus()
      }
    }}
    variant="outlined" size="small" onChange={(e)=> retail_qty_set(e.target.value)} />
          
                   
      </Grid>
</>):''

}
  


      <Grid item xs={12} sm={6}  style={{marginBottom:'10px'}}>
      <TextField type="number"  
      inputRef={item_rate_ref}
      onBlur={(e)=>e.target.value=='' || e.target.value < 0?item_rate_set(0):item_rate_set(e.target.value)}
      onKeyDown={(e)=>{
        if(e.key==='Enter'){
          addTOCart()
         
        }
      }}
     autoComplete='off' className={classes.fullWidth} 
     
   label="Rate" name="item_rate" value={item_rate} 
   variant="outlined" size="small" onChange={(e)=>item_rate_set(e.target.value)} />
         
                   
      </Grid>







      <Grid item xs={12} sm={6}  style={{marginBottom:'10px',display:'none'}}>
      <TextField type="number"  
      onBlur={(e)=>e.target.value=='' || e.target.value < 0?item_percentage_set(0):item_percentage_set(e.target.value)}
      onKeyDown={(e)=>{
        if(e.key==='Enter'){
          addTOCart()
        }
      }}
     autoComplete='off' className={classes.fullWidth} 
     
   label="% of Total Costing " name="item_percentage" value={item_percentage} 
   variant="outlined" size="small" onChange={(e)=>item_percentage_set(e.target.value)} />
         
                   
      </Grid>






      

  
 </Grid>


  
   
   



      <Grid item xs={12} sm={12} >
      <TextField type="number"  
     disabled
     autoComplete='off' className={classes.fullWidth} 
   label="Total" name="item_total" value={item_total} 
   variant="outlined" size="small" onChange={(e)=>item_total_set(e.target.value)} />
         
                   
      </Grid>

      

  <span>Press Enter Key to purchase Cart </span>


  <Grid item xs={12} sm={6} style={{marginTop: '20px'}}>
     <Button onClick={()=>addTOCart()} style={{background: 'rgb(109 113 113)',color: 'white',fontSize: '10px'}}>Add To Cart</Button>
  </Grid>





  <TableContainer >
<Table className={classes.table} size="small" aria-label="a dense table">
  <TableHead>
        <TableRow>
        <TableCell align="left"   style={{width:'1%'}}>SL</TableCell>
        <TableCell align="left"   style={{width:'15%'}}>Item</TableCell>
        {
          is_serial=='yes'?(<>
        <TableCell align="left"   style={{width:'14%'}}>Serials</TableCell>

          </>):''
        }
        
        {
          is_warehouse == 'yes'?(<>
            <TableCell align="left"   style={{width:'10%'}}>Warehouse</TableCell>
          </>):''
        }
        <TableCell align="right"  style={{width:'10%'}}>QTY</TableCell>
        <TableCell align="right"  style={{width:'10%'}}>Rate (Per)</TableCell>
        {/* <TableCell align="right"  style={{width:'10%'}}>% of Costing</TableCell> */}
      
       
        <TableCell align="right"  style={{width:'10%'}}>Total</TableCell>
        <TableCell align="right"  style={{width:'10%'}}>Actions</TableCell>
      </TableRow>
  </TableHead>
  <TableBody>
        {
          itemCart.map((item,sl)=>(<>
             <TableRow key={sl} style={{background: sl == pd_ind_id ? '#dedede':'',  transition: '1s'}}>
            <TableCell align="left">{sl+parseFloat(1)}</TableCell>
            <TableCell align="left">{item.item_name}</TableCell>

            {
              is_serial == 'yes'?(<>
               <TableCell align="left">
                {
                  item.is_serial == 'yes'?(<>
                      {
            
                      <>
                      ({item.serials.map((serial)=>(
                    <> <span>{serial.serial_number}</span>, </> 
                    ))})
                    </>
                      
                        }
                    
                  </>):''
                }
            </TableCell>
              
              </>):''
            }
           {
             is_warehouse =='yes'?(<>
                    <TableCell align="left">{item.warehouse_name}</TableCell>
             </>):''
           }
            <TableCell align="right">{item.qty_display}</TableCell>
            <TableCell align="right">{format(parseFloat(item.item_rate).toFixed(2))} ({item.per_unit_symbol})</TableCell>
            {/* <TableCell align="right">{format(parseFloat(item.item_percentage).toFixed(2))}</TableCell> */}
           
          
            <TableCell align="right">{format(parseFloat(item.item_total).toFixed(2))}</TableCell>
            <TableCell align="right">
            <EditIcon style={{cursor:'pointer',color:'#2e482e',marginLeft:'2px'}} onClick={()=>{editItemCart(item,sl)}}></ EditIcon>
            <span style={{width:'2px'}}> </span>
            
            <RemoveCircleIcon style={{cursor:'pointer',color:'#582727',marginLeft:'2px'}} onClick={()=>{itemCartItemRemove(sl,item)}}></ RemoveCircleIcon>
            </TableCell>
        
            </TableRow>
          </>))
        }

        {
          itemCart.length > 0 ? (<>
          <TableRow style={{background: 'rgb(249 243 243)',transition: '1s'}}>
              <TableCell align="right" colSpan={is_serial == 'yes'? (is_warehouse =='yes' ? 5 : 5-1) :(is_warehouse =='yes' ? 4 : 4-1)} style={{fontWeight:'bold'}}>Total  : </TableCell>
              <TableCell align="right" style={{fontWeight:'bold'}}></TableCell>
              <TableCell align="right" style={{fontWeight:'bold'}}> {format(parseFloat(_.sumBy(itemCart,(item)=>parseFloat(item.item_total))).toFixed(2))}</TableCell>
           </TableRow>
          </>):''
           
        }
       
   



  </TableBody>
</Table>
</TableContainer>

  



        </Grid>


        <Grid item xs={12} sm={3} style={{marginTop: '8px',
    padding: '15px'}}>

              <TextField  autoComplete='off' className={classes.fullWidth} 
            
              label="Voucher No" name="mf_voucher_no" value={mf_voucher_no} disabled
              variant="outlined" size="small" onChange={(e)=>mf_voucher_no_set(e.target.value)} />
             

                <LocalizationProvider dateAdapter={AdapterDateFns} style={{marginTop: '8px'}}>
              <Stack spacing={3}>
                <DesktopDatePicker
                 readOnly={authInfo.role == 'user' ? true : false}
                  label="Entry Date" 
                  inputFormat={dateTimeFormat}
                  value={created_date}
                  onChange={(e)=>created_date_set(e)}
                  renderInput={(params) => <TextField {...params} />}
                />
                
              </Stack>
              </LocalizationProvider>
             
               

            <TableContainer  style={{marginTop: '8px'}}>
              <Table className={classes.table} size="small" aria-label="a dense table">
              
              
                
              
             <TableRow > 
             <TableCell><TextareaAutosize 
               
               
               onChange={(e)=>{narration_set(e.target.value)}} value={narration}  name="narration" style={{float:'left',width: '100%'}} aria-label="Narration..." rowsMin={3} placeholder="Narration..." />
             </TableCell>


             <TableCell colSpan={2}></TableCell>

           
          
          </TableRow>
        
      </Table>
    </TableContainer>



    <TextField  style={{marginTop: '8px'}} type="number" autoComplete='off' className={classes.fullWidth} label="Labour Cost" name="labour_cost" 
      onBlur={(e)=>{
        if(e.target.value==''){
          labour_cost_set(0)
        }
      }}
    value={labour_cost} variant="outlined" size="small" onChange={(e)=>labour_cost_set(e.target.value)} />

    
<TextField  style={{marginTop: '8px'}} type="number" autoComplete='off' className={classes.fullWidth} label="Others Cost" name="others_cost" 
  onBlur={(e)=>{
    if(e.target.value==''){
      others_cost_set(0)
    }
  }}
    value={others_cost} variant="outlined" size="small" onChange={(e)=>others_cost_set(e.target.value)} />

<TextField  style={{marginTop: '8px'}} type="number" autoComplete='off' disabled className={classes.fullWidth} label="Total Cost" name="total_cost" 
 onBlur={(e)=>{
  if(e.target.value==''){
    total_cost_set(0)
  }
}}
    value={total_cost} variant="outlined" size="small" onChange={(e)=>total_cost_set(e.target.value)} />


                  <Button  style={{marginTop: '25px',fontSize: '12px'}}
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={loadingSave}
                      className={classes.button}
                      startIcon={<SaveIcon />}
                      onClick={savePurchaseOrder}
                    >
                      Save 
                    </Button>
          


               <Button 
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.button}
                  style={{color: 'white',
                  fontSize:'14px',
                    backgroundColor: '#115f5f',
                    marginTop: '30px'}}
                  disabled={loadingSave}
                  onClick={()=>{
                    window.location.reload();
                    return false;
                  }}
                >
                  Reset
                </Button>
          



          </Grid>

      </Grid>

   
  

     </Paper>











       {/* Switch Branch  Modal */}
   <Modal
        open={serialModal}
        onClose={() => serialModalSet(false)}
        center
        style={{minWidth:'600px !important',minHeight:'500px'}}
 
      
      >
        <h2 style={{margin: 0,
    padding: 0,
    color: '#0f7e77'}}>Serial Number Add of <p style={{fontSize:'10px',margin: 0,
    padding: 0,color:'red'}}>{selectedItem != null ? selectedItem.item_name :''}</p></h2>
        <Grid item xs={12} sm={12} style={{display:'flex',marginTop:'5px',  display: 'flex',
   }} > 
      
        <Grid item  xs={12} sm={10}>
            <TextField label="Serail Number" variant="outlined"  size="small" 
             className={classes.fullWidth} value={serial_number} 
              onChange={(e)=>serial_number_set(e.target.value)}
              name="serial_number" 
             onKeyDown={(e)=>{
                  if(e.key == 'Enter'){
                    serialAdd()
                  }
             }}
              />
          </Grid> 

          <Grid item  xs={12} sm={2}>
              <Button style={{background:'green',fontSize:'20px',
               background: '#03d4be',
    fontSize: '15px'}}
    onClick={()=>serialAdd()}
    >Add</Button> 
          </Grid> 
      
          



         
        </Grid>


        <br/>
          <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left">SL</TableCell>
            <TableCell align="left">Serial</TableCell>
            <TableCell align="right">Remove</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

        
        
        {
            serials.map((serial,ind)=>(
              <TableRow>
              <TableCell align="left">{ind+parseFloat(1)}</TableCell>
              <TableCell align="left">{serial.serial_number}</TableCell>
              <TableCell align="right"  ><span style={{color:'red',fontSize:'18px',cursor:'pointer',margin:0,padding:0}} onClick={()=>serialRemove(ind)}>X</span></TableCell>
            </TableRow>
            ))
          }
        



        </TableBody>
      </Table>
    </TableContainer>



      </Modal>


     

     
    </div>
        
    );
}


 const options = {
   filterType: 'checkbox',
 };
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    color: 'white',
    fontSize:'10px',
    backgroundColor: '#115f5f'
  },

root: {},
'& .MuiTextField-root': {
  margin: theme.spacing(1),
  width: '25ch',
 },
  paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
  },
  pageEntryLabel:{
      color: '#240275',
      margin: '0px',
      padding: '0px',
      marginTop: '-11px',
      textAlign: 'left',
      marginLeft: '-8px',
      marginBottom: '5px'
  },
  fullWidth:{
      width:'100%'
  },
  option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
    plusLinkDiv:{
        position:'relative'  
  },
  plusLink:{
    margin: 0,
    padding: 0,
    marginTop: '-21px',
    fontSize: '29px',
    height: '21px',
    textAlign: 'right',
    position: 'absolute',
    right: 0,
    color: '#3e8d54'
  }
}));

const mapStateToPops = (state)=>{
      return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToPops,{currentRouteSet})(GroupManage);